<script setup>

import AppIdea from '@/components/AppIdea.vue'

import store from '@/store'

const props = defineProps(['date'])

const api = store.state.api

let ideas
switch (props.date) {
  case 'today':
    await store.dispatch('updateLastTodayThinksList')
    ideas = store.state.thinksList.today
    break
  case 'this-week':
    await store.dispatch('updateLastThisWeekThinksList')
    ideas = store.state.thinksList.thisWeek
    break
  default:
    ideas = await api.getLastThinks(1000, true)
    for (let i = 0; i < ideas.length; i++) {
      ideas[i].tags = JSON.parse(ideas[i].tags)
    }
    break
}
</script>

<template>
<ul class="thinks-thinks-list">
  <li class="thinks-thinks-list__item" v-for="idea in ideas" :key="idea.id">
    <AppIdea :idea="idea"></AppIdea>
  </li>
</ul>
</template>

<style scoped lang="scss">
.thinks-thinks-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 15px;
}
</style>
