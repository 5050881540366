<script setup>
import AppTaskTag from '@/components/AppTaskTag.vue'

const props = defineProps({
  idea: {
    type: Object,
    required: true
  }
})
</script>

<template>
  <article class="idea-block">
    <div class="tags">
      <AppTaskTag :tag="tag" v-for="tag in props.idea.tags" :key="tag.name"></AppTaskTag>
    </div>
    <p class="idea-name">{{ props.idea.name }}</p>
  </article>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.idea-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 20px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 10px 20px 15px 20px;

  border-radius: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

  cursor: pointer;

  transition: $transition;

  &:hover {
    transform: scale(102%);
  }

  &:active {
    transform: scale(105%);
  }
}

.idea-name {
  margin-top: 5px;
}
</style>
