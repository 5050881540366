<script setup>

</script>

<template>
<h1 class="app-heading-block"><slot></slot></h1>
</template>

<style scoped lang="scss">
.app-heading-block {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
