<script setup>
import AppTaskTag from '@/components/AppTaskTag.vue'
import AppAddButton from '@/components/AppAddButton.vue'
import store from '@/store'

const props = defineProps({
  plan: {
    type: Object,
    required: true
  },
  month: {
    type: Object,
    required: false
  }
})

function addSubtask () {
  const subtask = prompt('Название подзадачи', 'Новая подзадача')
  store.dispatch('addSubtask', {
    id: props.plan.id,
    year: props.month.getFullYear(),
    month: props.month.getMonth() + 1,
    name: subtask
  })
}

async function checkboxClick (subtask) {
  subtask.checkbox = !subtask.checkbox
  await store.state.api.changePlanSubtaskStatus(subtask, props.plan)
}
</script>

<template>
  <div class="app-plan-block">
    <div class="left-part">
      <div class="name-block">
        <h3 class="plan-name">{{ props.plan.name }}</h3>
        <AppAddButton class="app-add-btn" @click="addSubtask" title="Создать подзадачу"></AppAddButton>
      </div>
      <ul class="subtask-list">
        <li class="subtask-list__item" v-for="subtask in props.plan.subtasks" :key="subtask.id">
          <input class="checkbox" type="checkbox" name="" id="" v-model="subtask.checkbox" @input="checkboxClick(subtask)">
          <p class="subtask-name">{{ subtask.name }}</p>
        </li>
      </ul>
    </div>
    <div class="right-part">
      <ul class="tags-list">
        <li class="tags-list__item" v-for="tag in props.plan.tags" :key="tag.name">
          <AppTaskTag :tag="tag"></AppTaskTag>
        </li>
      </ul>
      <div class="control-buttons">
        <div class="control-button postponement-button"></div>
        <div class="control-button cancel-button"></div>
        <input class="checkbox" type="checkbox" name="" id="">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-plan-block {
  display: grid;
  grid-template-columns: 5fr 2fr;

  padding: 15px 10px;

  border-bottom: 1px solid #D9D9D9;
}

.app-add-btn {
  width: 20px;
  height: 20px;
  font-size: 16px;
}

.plan-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.subtask-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.subtask-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.right-part {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.control-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.control-button {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  cursor: pointer;
}

.postponement-button {
  width: 10px;
  height: 20px;

  background-image: url("@/assets/img/icons/arrow-for-button.svg");
}

.cancel-button {
  width: 15px;
  height: 15px;

  background-image: url("@/assets/img/icons/cancel.svg");
}

.checkbox {
  width: 20px;
  height: 20px;
}

.name-block {
  display: flex;
  align-items: center;
  column-gap: 10px;

  margin-bottom: 15px;
}
</style>
