<script setup>

import AppAside from '@/components/AppAside.vue'
import AppAsideMobile from '@/components/AppAsideMobile.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppHeading1 from '@/components/AppHeading1.vue'
import AppButtonSwitcher from '@/components/AppButtonSwitcher.vue'
import AppCalendarButton from '@/components/AppCalendarButton.vue'
import AppSliderArrowButton from '@/components/AppSliderArrowButton.vue'
import AppPlansList from '@/components/AppPlansList.vue'
import AppEventsList from '@/components/AppEventsList.vue'
import AppAddButton from '@/components/AppAddButton.vue'
import { ref } from 'vue'

const buttonSwitcherOptions = [
  {
    name: 'month',
    value: 'Месяц'
  },
  {
    name: 'year',
    value: 'Год'
  }
]

let nextMonth = new Date()
if (nextMonth.getMonth() === 11) {
  nextMonth = new Date(nextMonth.getFullYear() + 1, 0, 1)
} else {
  nextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1)
}

function leftSlide () {
  rightMonth.value = leftMonth.value
  const nextLeftMonth = new Date(`${rightMonth.value.toISOString()}`)
  nextLeftMonth.setDate(0)
  leftMonth.value = nextLeftMonth
}

function rightSlide () {
  leftMonth.value = rightMonth.value
  const nextMonth = leftMonth.value
  if (nextMonth.getMonth() === 11) {
    nextMonth.value = new Date(nextMonth.getFullYear() + 1, 0, 1)
  } else {
    nextMonth.value = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1)
  }
  rightMonth.value = nextMonth.value
}

const leftMonth = ref(new Date())
const rightMonth = ref(nextMonth)
</script>

<template>
  <nav>
    <AppAsideMobile class="app-aside-mobile"></AppAsideMobile>
  </nav>
  <div class="app-container">
    <nav>
      <AppAside class="app-aside"></AppAside>
    </nav>
    <div class="main-block">
      <AppHeader></AppHeader>
      <main class="main">
        <div class="main-header">
          <AppHeading1 class="app-heading">Планы</AppHeading1>
          <AppButtonSwitcher :options="buttonSwitcherOptions"></AppButtonSwitcher>
          <AppCalendarButton></AppCalendarButton>
          <router-link to="/add-plans"><AppAddButton></AppAddButton></router-link>
        </div>
        <div class="main-slider">
          <AppSliderArrowButton class="l-arrow" direction="left" @click="leftSlide"></AppSliderArrowButton>
          <AppPlansList class="left-list" :month="leftMonth"></AppPlansList>
          <AppPlansList class="right-list" :month="rightMonth"></AppPlansList>
          <AppSliderArrowButton class="r-arrow" direction="right" @click="rightSlide"></AppSliderArrowButton>
        </div>
        <section class="events-section">
          <div class="events-heading-block">
            <h2 class="events-heading">События:</h2>
            <router-link to="/add-event"><AppAddButton></AppAddButton></router-link>
          </div>
          <AppEventsList></AppEventsList>
        </section>
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-container {
  display: grid;
  grid-template-columns: 120px 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main {
  height: 100vh;

  padding: 0 30px 15%;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    height: auto;
    overflow: visible;
  }
}

.main-slider {
  display: grid;
  grid-template-columns: 30px 1fr 1fr 30px;
  grid-template-areas: 'l_arrow left right r_arrow';
  column-gap: 10px;

  margin-top: 15px;

  min-height: 30%;

  @media screen and (max-width: 768px) {
    grid-template-areas: 'l_arrow left' 'right r_arrow';
  }
}

.left-list {
  grid-area: left;
}

.right-list {
  grid-area: right;
}

.app-aside-mobile {
  display: none;
}

.l-arrow {
  grid-area: l_arrow;
}

.r-arrow {
  grid-area: r_arrow;
}

.main-header {
  display: flex;
  align-items: center;
  gap: 20px;

  grid-area: header;
}

.events-section {
  margin-top: 30px;
}

.events-heading {
  margin-top: 15px;
  margin-bottom: 15px;

  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.events-heading-block {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .main {
    margin-bottom: 50px;
  }
  .app-aside {
    display: none;
  }
  .app-aside-mobile {
    display: block;
  }
  .app-container {
    margin-bottom: 30px;
  }
}
</style>
