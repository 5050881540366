<script setup>
import { reactive } from 'vue'

function stringifyMonth (month) {
  const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

  return months[month]
}

function getCurrentDate () {
  const date = new Date()
  return {
    day: date.getDate(),
    month: stringifyMonth(date.getMonth()),
    year: date.getFullYear()
  }
}

function updateDate () {
  const date = getCurrentDate()
  currentDate.day = date.day
  currentDate.month = date.month
  currentDate.year = date.year
}

const currentDate = reactive({
  day: null,
  month: null,
  year: null
})
updateDate()

setInterval(updateDate, 1000)
</script>

<template>
  <div class="date-block">
    <p class="date">{{ currentDate.day }} {{currentDate.month}} {{currentDate.year}}</p>
  </div>
</template>

<style scoped lang="scss">
.date-block {
  display: flex;
  gap: 5px;
}

.date {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
