<script setup>

import AppTask from '@/components/AppTask.vue'

const props = defineProps({
  tasks: {
    type: Object,
    required: true
  }
})
</script>

<template>
<ul class="app-tasks-list-block">
  <li class="app-tasks-list__item" v-for="task in props.tasks" :key="task.id">
    <AppTask :task="task"></AppTask>
  </li>
</ul>
</template>

<style scoped lang="scss">
.app-tasks-list-block {
  margin-top: 5px;
  margin-bottom: 15px;
}
</style>
