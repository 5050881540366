<script setup>

const props = defineProps({
  icon: String,
  to: String
})
</script>

<template>
  <router-link :to="props.to">
    <div class="button">
      <div class="icon" :style="{backgroundImage: `url('${props.icon}')`}"></div>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.button {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  transition: $transition;

  &:active {
    transform: translateY(-10%);
  }
}

.icon {
  width: 40px;
  height: 40px;

  margin-bottom: 5px;

  background-repeat: no-repeat;
  background-size: cover;
}
</style>
