<script setup>
const emit = defineEmits(['click'])
</script>

<template>
<div class="app-add-button-block" @click="emit('click')">+</div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.app-add-button-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  width: 30px;
  height: 30px;

  background-color: $accentColor;
  color: #FFF;

  border-radius: 50%;

  cursor: pointer;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  transition: $transition;

  &:hover {
    transform: scale(105%);
  }

  &:active {
    transform: scale(110%) rotate(10deg);
  }
}
</style>
