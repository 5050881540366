<script setup>
const props = defineProps(['id', 'name', 'placeholder'])
</script>

<template>
  <div class="main-form__item">
    <label :for="props.id" class="main-form-label">{{ props.name }}</label>
    <input type="text" :name="props.id" :id="props.id" class="main-form-text-input" :placeholder="props.placeholder">
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables.scss' as *;

.main-form__item {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.main-form-text-input {
  height: 50px;

  padding-left: 25px;
  padding-right: 15px;

  outline: none;
  border: none;

  border-radius: 25px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 74, 173, 0.45);
  transition: $transition;

  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 74, 173, 0.65);
  }

  &:focus {
    box-shadow: 0px 2px 10px 0px rgba(0, 74, 173, 0.85);
  }
}
</style>
