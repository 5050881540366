<script setup>

import AsideButton from '@/components/aside/AsideButton.vue'

const sections = [
  {
    name: '',
    icon: '/img/icons/home.svg',
    link: '/home'
  },
  {
    name: 'Задачи',
    icon: '/img/icons/task.svg',
    link: '/tasks'
  },
  {
    name: 'Планы',
    icon: '/img/icons/calendar.svg',
    link: '/plans'
  },
  {
    name: 'Привычки',
    icon: '/img/icons/placemark.svg',
    link: '/habits'
  },
  {
    name: 'Мысли',
    icon: '/img/icons/brain.svg',
    link: '/thinks'
  },
  {
    name: 'Заметки',
    icon: '/img/icons/notepad.svg',
    link: '/notes'
  },
  {
    name: 'Уведомления',
    icon: '/img/icons/bell.svg',
    link: '/notifications'
  },
  {
    name: 'Статистика',
    icon: '/img/icons/stats.svg',
    link: '/stats'
  }
]
</script>

<template>
  <div class="body">
    <img src="@/assets/img/logo.svg" alt="EvoluteYou" class="logo">
    <ul class="btns-list" id="buttonsList">
      <li class="btns-list__item" v-for="btn in sections" :key="btn.name">
        <AsideButton :icon="btn.icon" :to="btn.link">{{ btn.name }}</AsideButton>
      </li>
    </ul>
    <AsideButton class="settings-btn" icon="/img/icons/settings.svg" to="/settings"></AsideButton>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;
@use '@/assets/scss/mixins' as *;

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  height: 100vh;
  width: 120px;

  overflow-y: auto;
  @include removeScrollbar;

  padding-top: 40px;
  padding-bottom: 75%;

  background-color: $accentColor;
}

.logo {
  margin-bottom: 20px;
}

.btns-list {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  row-gap: 20px;

  width: 100%;

  &__item {
    width: 100%;
  }
}

.settings-btn {
  padding-top: 20px;
  margin-top: auto;
}
</style>
