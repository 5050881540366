<script setup>
import store from '@/store'
import router from '@/router'
import { ref } from 'vue'

if (await store.state.user.isEmailVerified()) router.push('/home')

document.querySelector('body').classList.add('noFullScreen')

const code = ref('')

async function formSubmit () {
  const result = await store.state.api.verifyEmail(code.value)
  if (result) {
    await router.push('/home')
  } else {
    alert('Не удалось подтвердить E-mail!')
  }
}
</script>

<template>
  <div class="login-view">
    <h1 class="login-heading">Подтверждение E-mail</h1>
    <p class="login-heading login-heading-description">На ваш E-mail было отправлено сообщение с кодом, введите его в поле ниже</p>
    <form class="login-form">
      <label for="loginFormEmail" class="login-label">Код подтверждения</label>
      <input class="login-form-text-input" id="verificationCode" type="text" placeholder="Код подтверждения" v-model="code">
      <button class="login-form-button" @click.prevent="formSubmit">Далее</button>
    </form>
  </div>
</template>

<style scoped lang="scss">
@use "/src/assets/scss/variables" as *;

.login-heading {
  color: #FFFFFF;
  font-size: 40px;
}

.login-heading-description {
  max-width: 70vw;
  font-size: 24px;
  text-align: center;
}

.login-label {
  align-self: flex-start;

  color: #555555;
  margin-bottom: 15px;
  margin-left: 5px;
  font-size: 18px;
}

.login-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 40px;

  gap: 50px;

  min-height: 100vh;

  background-color: $accentColor;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 60px;

  border-radius: 20px;
  background-color: #fff;

  box-shadow: #fff 0 0 20px;
}

.left-part {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-text-input {
  padding: 15px 30px;

  outline: none;
  border: none;
  border-radius: 20px;

  box-shadow: #999 0 0 10px;

  transition: $transition;

  &:hover {
    box-shadow: #777 0 0 10px;
  }

  &:focus {
    box-shadow: $accentColor 0 0 15px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.login-form-button {
  margin-top: 20px;
  padding: 15px 30px;

  border-radius: 20px;

  border: none;
  outline: none;
  background-color: $accentColor;
  color: #fff;

  cursor: pointer;

  transition: $transition;

  &:hover {
    background-color: darken($accentColor, 5%);
  }

  &:active {
    background-color: darken($accentColor, 10%);
    transform: scale(105%);
    box-shadow: $accentColor 0 0 10px;
  }
}

.login-register-link {
  margin-top: 20px;

  color: $accentColor;

  &:hover {
    color: darken($accentColor, 5%);
  }
}
</style>
