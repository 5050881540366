<script setup async>
import WeatherIcon from '@/components/home/weather/WeatherIcon.vue'
import store from '@/store'
import { ref } from 'vue'

const user = store.state.user

let temperature = ref(undefined)
const icon = ref(undefined)

const api = store.state.api

async function getWeather () {
  const weather = await api.getWeather(user.getCity())
  if (!weather) {
    temperature = false
    return
  }
  temperature.value = Math.round(weather.main.temp)
  if (weather.weather.id >= 200 && weather.weather.id < 300) {
    icon.value = 'rainThunderstormCloud'
  } else if (weather.weather[0].id >= 300 && weather.weather[0].id < 400) {
    icon.value = 'rainCloud'
  } else if (weather.weather[0].id >= 500 && weather.weather[0].id < 600) {
    icon.value = 'heavyRainCloud'
  } else if (weather.weather[0].id >= 600 && weather.weather[0].id < 700) {
    icon.value = 'snow'
  } else if (weather.weather[0].id >= 700 && weather.weather[0].id < 800) {
    icon.value = 'mistCloud'
  } else if (weather.weather[0].id === 800) {
    icon.value = 'sun'
  } else {
    icon.value = 'cloud'
  }
}

await getWeather()
</script>

<template>
  <div class="home-weather" v-if="temperature">
    <WeatherIcon :weather="icon"></WeatherIcon>
    <div class="temperature">{{ temperature }} °С</div>
  </div>
</template>

<style scoped lang="scss">
.home-weather {
  display: flex;
  align-items: center;
  gap: 10px;
}

.temperature {
  font-size: 28px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
  white-space: nowrap;
}
</style>
