export default class API {
  constructor (url, store) {
    this.url = url
    this.store = store
  }

  async authenticate (email, password) {
    const response = await fetch(this.url + '/login', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
    if (response.status === 200) {
      this.store.commit('setUser', await response.json())
      return true
    } else {
      return false
    }
  }

  async logout () {
    const response = await fetch(this.url + '/logout', {
      credentials: 'include',
      method: 'POST'
    })
    if (response.status === 200) {
      this.store.commit('setUser', null)
      return true
    } else {
      return false
    }
  }

  async register (name, surname, username, email, password) {
    const response = await fetch(this.url + '/register', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        surname: surname,
        username: username,
        email: email,
        password: password
      })
    })
    if (response.status === 200) {
      this.store.commit('setUser', await response.json())
      return true
    } else {
      return false
    }
  }

  async checkEmailVerification () {
    // ToDo: realize
    /* const response = await fetch(this.url + '/email-verification', {
      credentials: 'include',
      method: 'GET'
    })
    return response.status === 200 */
    return false
  }

  async verifyEmail (code) {
    const response = await fetch(this.url + '/email-verification', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: code
      })
    })
    return response.status === 200
  }

  async checkSession () {
    const response = await fetch(this.url + '/user', {
      credentials: 'include',
      method: 'GET'
    })
    return response.status === 200
  }

  async addTask (name, tags, date) {
    const response = await fetch(this.url + '/task/add', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        tags: tags,
        date: date.toISOString()
      })
    })
    if (response.status === 200) return true
    return false
  }

  async addEvent (name, tags, date) {
    const response = await fetch(this.url + '/event/add', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        tags: tags,
        date: date.toISOString()
      })
    })
    if (response.status === 200) return true
    return false
  }

  async addNote (text, tags, date) {
    const response = await fetch(this.url + '/note/add', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        tags: tags,
        date: date.toISOString()
      })
    })
    if (response.status === 200) return true
    return false
  }

  async addThink (text, tags) {
    const response = await fetch(this.url + '/think/add', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        tags: tags
      })
    })
    return response.status === 200
  }

  async addPurpose (year, month, name) {
    const response = await fetch(this.url + '/plans/add', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        year: year,
        month: month,
        name: name
      })
    })
    if (response.status === 200) return true
    return false
  }

  async addSubtask (id, name) {
    const response = await fetch(this.url + '/plan/subtask/add', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        name: name
      })
    })
    if (response.status === 200) return true
    return false
  }

  async changePlanSubtaskStatus (subtask, plan) {
    const response = await fetch(this.url + '/plan/subtask/change-status', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: plan.id,
        subtask: subtask.name
      })
    })
    if (response.status === 200) return true
    return false
  }

  async getTodayTasks () {
    const response = await fetch(this.url + '/tasks', {
      credentials: 'include'
    })
    const tasks = await response.json()
    return tasks
  }

  async getTasksByDate (date) {
    const response = await fetch(this.url + '/tasks/date/' + date.toISOString(), {
      credentials: 'include'
    })
    const tasks = await response.json()
    return tasks
  }

  async getWeather (city) {
    const apiResponse = await fetch(this.url + `/weather/${city}`)
    if (apiResponse.status !== 200) return false
    return apiResponse.json()
  }

  async getTelegramBotToken () {
    const response = await fetch(this.url + '/user/token', {
      credentials: 'include'
    })
    const token = await response.text()
    return token
  }

  async setTaskCompleteStatus (taskID, status) {
    let response
    try {
      response = await fetch(this.url + '/task/set-complete', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: taskID,
          completeStatus: status
        })
      })
    } catch (error) {
      alert('Возникла ошибка!')
    }
    this.store.dispatch('updateTodayTasksList')
    return response.status === 200
  }

  async cancelTask (taskID) {
    let response
    try {
      response = await fetch(this.url + '/task/cancel', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: taskID
        })
      })
    } catch (error) {
      alert('Возникла ошибка!')
    }
    this.store.dispatch('updateTodayTasksList')
    return response.status === 200
  }

  async rescheduleTask (taskID, newDate) {
    let response
    try {
      response = await fetch(this.url + '/task/reschedule', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: taskID,
          date: newDate.toISOString()
        })
      })
    } catch (error) {
      alert('Возникла ошибка!')
    }
    this.store.dispatch('updateTodayTasksList')
    return response.status === 200
  }

  async getLastNotes (count, isNotThisWeek = false) {
    const response = await fetch(this.url + (!isNotThisWeek ? `/notes/last/${count}` : `/notes/last/${count}?isNotThisWeek=true`), {
      credentials: 'include'
    })
    const notes = await response.json()
    return notes
  }

  async getLastThinks (count, isNotThisWeek = false) {
    const response = await fetch(!isNotThisWeek ? this.url + `/thinks/last/${count}` : this.url + `/thinks/last/${count}?isNotThisWeek=true`, {
      credentials: 'include'
    })
    const notes = await response.json()
    return notes
  }

  async getLastTodayThinks (count) {
    const response = await fetch(this.url + `/thinks/last/${count}/today`, {
      credentials: 'include'
    })
    const notes = await response.json()
    return notes
  }

  async getLastThisWeekThinks (count) {
    const response = await fetch(this.url + `/thinks/last/${count}/this-week`, {
      credentials: 'include'
    })
    const notes = await response.json()
    return notes
  }

  async getThisWeekNotes () {
    const response = await fetch(this.url + '/notes/this-week', {
      credentials: 'include'
    })
    const notes = await response.json()
    return notes
  }

  async getMonthPlans (year, month) {
    const response = await fetch(this.url + `/plans/month/${year}/${month}`, {
      credentials: 'include'
    })
    const plans = await response.json()
    return plans
  }

  async getEvents () {
    const currentDate = new Date()
    const response = await fetch(this.url + `/events/month/${currentDate.getFullYear()}/${currentDate.getMonth() + 1}`, {
      credentials: 'include'
    })
    const events = await response.json()
    return events
  }
}
