<script setup>
const props = defineProps(['value', 'id'])
const emit = defineEmits(['submit'])
</script>

<template>
  <input type="submit" :id="props.id" :name="props.id" :value="props.value" class="app-form-submit"
         @click.prevent="emit('submit')">
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables.scss' as *;

.app-form-submit {
  margin-top: 10px;
  padding: 15px 20px 15px 20px;

  border: none;
  border-radius: 20px;

  cursor: pointer;

  background-color: $accentColor;
  color: #FFFFFF;
  font-size: 18px;

  transition: $transition;

  &:hover {
    background-color: darken($accentColor, 10%);
    transform: scale(102%);
  }

  &:active {
    background-color: darken($accentColor, 20%);
    transform: scale(105%);
  }
}
</style>
