<script setup>
import AppNotificationQuestionButton from '@/components/AppNotificationQuestionButton.vue'

const props = defineProps({
  notification: {
    type: Object,
    required: true
  }
})

let background = ''
let icon = ''

switch (props.notification.type) {
  case 'miss':
    background = 'linear-gradient(87deg, #A00000 2.43%, #FF3D00 100%)'
    icon = '/img/icons/bell.svg'
    break
  case 'info':
    background = 'linear-gradient(87deg, #0040A0 2.43%, #0095D4 100%)'
    icon = '/img/icons/bell.svg'
    break
  case 'question':
    background = 'linear-gradient(87deg, #0040A0 2.43%, #0095D4 100%)'
    icon = '/img/icons/bell.svg'
    break
}
</script>

<template>
  <div class="app-notification-block" :style="{background: background}">
    <div class="icon" :style="{backgroundImage: `url('${icon}'`}"></div>
    <div class="main">
      <p class="notification-text">{{ props.notification.text }}</p>
      <div class="question-block" v-if="props.notification.type === 'question'">
        <AppNotificationQuestionButton :value="button" v-for="button in props.notification.answers" :key="button"></AppNotificationQuestionButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.app-notification-block {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 15px 35px 15px 10px;

  border-radius: 15px;

  transition: $transition;

  &:hover {
    transform: scale(105%);
  }
}

.icon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.notification-text {
  color: #FFFFFF;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.question-block {
  display: flex;
  align-items: center;
  gap: 5px;

  margin-top: 10px;
}
</style>
