import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import TasksView from '@/views/TasksView.vue'
import PlansView from '@/views/PlansView.vue'
import HabitsView from '@/views/HabitsView.vue'
import ThinksView from '@/views/ThinksView.vue'
import NotesView from '@/views/NotesView.vue'
import AddNoteView from '@/views/AddNoteView.vue'
import AddTaskView from '@/views/AddTaskView.vue'
import AddThinkView from '@/views/AddThinkView.vue'
import AddPlansView from '@/views/AddPlansView.vue'
import AddEventView from '@/views/AddEventView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import EmailVerificationView from '@/views/EmailVerificationView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import SettingsView from '@/views/SettingsView.vue'
import StatsView from '@/views/StatsView.vue'

const routes = [
  {
    path: '/',
    alias: ['/home'],
    name: 'home',
    component: HomeView
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView
  },
  {
    path: '/plans',
    name: 'plans',
    component: PlansView
  },
  {
    path: '/habits',
    name: 'habits',
    component: HabitsView
  },
  {
    path: '/thinks',
    name: 'thinks',
    component: ThinksView
  },
  {
    path: '/notes',
    name: 'notes',
    component: NotesView
  },
  {
    path: '/add-note',
    name: 'add-note',
    component: AddNoteView
  },
  {
    path: '/add-task',
    name: 'add-task',
    component: AddTaskView
  },
  {
    path: '/add-think',
    name: 'add-think',
    component: AddThinkView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: EmailVerificationView
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView
  },
  {
    path: '/add-plans',
    name: 'add-plans',
    component: AddPlansView
  },
  {
    path: '/add-event',
    name: 'add-event',
    component: AddEventView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
