<script setup>
const props = defineProps({
  note: {
    type: Object,
    required: true
  },
  showCreateTime: {
    type: Boolean,
    required: false,
    default: false
  },
  showDeadline: {
    type: Boolean,
    required: false,
    default: false
  }
})

let expireDate = new Date(props.note.expireDate)
expireDate = new Date(Date.UTC(expireDate.getFullYear(), expireDate.getMonth(), expireDate.getDate(), expireDate.getHours(), expireDate.getMinutes()))
const createDate = new Date(props.note.createDate)
</script>

<template>
  <article class="app-note-block">
    <p class="note-text">{{ props.note.text }}</p>
    <div class="note-meta">
      <div class="deadline" title="Срок заметки" v-if="props.showDeadline">
        <div class="deadline-icon"></div>
        <p class="deadline-value">{{ expireDate.toLocaleDateString() }},
          {{ expireDate.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}) }}</p>
      </div>
      <div class="create-time" v-if="props.showCreateTime">{{ createDate.toLocaleDateString() }},
        {{ createDate.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}) }}
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.app-note-block {
  min-height: 100px;
  max-height: 400px;

  margin-left: 4px;
  margin-right: 4px;
  padding: 10px 20px;
  overflow-y: auto;

  border-radius: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

  cursor: pointer;

  transition: $transition;

  &:hover {
    transform: scale(102%);
  }

  &:active {
    transform: scale(105%);
  }
}

.note-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.deadline {
  display: flex;
  align-items: center;

  margin-top: 5px;
  padding: 5px;

  border-radius: 5px;
  box-shadow: 0 0 8px 0 #FFFFFF;

  background-color: #FFFFFF;
}

.deadline-icon {
  width: 16px;
  height: 16px;

  margin-right: 5px;

  background-image: url("@/assets/img/icons/clock.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.deadline-value {
  color: $accentColor;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.create-time {
  align-self: flex-end;

  padding: 3px 5px;

  color: #FFFFFF;
  background-color: #0094FF;

  border-radius: 5px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>
