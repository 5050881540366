<script setup>
import AppAddButton from '@/components/AppAddButton.vue'
import AppTasksList from '@/components/AppTasksList.vue'
import { computed } from 'vue'
import store from '@/store'

await store.dispatch('updateTodayTasksList')

const countCompletedTasks = computed(() => {
  let count = 0
  for (const task of store.state.todayTasksList) {
    if (task.isCompleted) count++
  }
  return count
})
</script>

<template>
  <div class="home-tasks-header">
    <h2 class="heading">На сегодня запланировано {{ store.state.todayTasksList.length }} задач</h2>
    <router-link to="/add-task">
      <AppAddButton></AppAddButton>
    </router-link>
  </div>
  <div class="home-tasks-block">
    <AppTasksList :tasks="store.state.todayTasksList"></AppTasksList>
  </div>
  <p class="counter-completed-tasks">Выполнено <span>{{ countCompletedTasks }}</span> задач из <span>{{
      store.state.todayTasksList.length
    }}</span></p>
</template>

<style scoped lang="scss">
@use '@/assets/scss/mixins' as *;

.home-tasks-block {
  max-height: 40vh;
  overflow-y: auto;
  @include removeScrollbar;
}

.home-tasks-header {
  display: flex;
  align-items: center;
  gap: 20px;

  margin-top: 15px;
}

.heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
}

.counter-completed-tasks {
  font-size: 14px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;

  margin-top: 10px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
