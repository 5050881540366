<script setup>
import AppAsideMobile from '@/components/AppAsideMobile.vue'
import AppHeading1 from '@/components/AppHeading1.vue'
import AppAside from '@/components/AppAside.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppMonthPicker from '@/components/AppMonthPicker.vue'
import AppPlansList from '@/components/AppPlansList.vue'
import { ref } from 'vue'

function monthChange (month) {
  selectedMonth.value = new Date(month)
  console.log(month)
}

const selectedMonth = ref(new Date())
</script>

<template>
  <nav>
    <AppAsideMobile class="app-aside-mobile"></AppAsideMobile>
  </nav>
  <div class="app-container">
    <nav>
      <AppAside class="app-aside"></AppAside>
    </nav>
    <div class="main-block">
      <AppHeader></AppHeader>
      <main class="main">
        <div class="main-header">
          <AppHeading1>Планирование на месяц</AppHeading1>
          <AppMonthPicker @change="monthChange($event)"></AppMonthPicker>
        </div>
        <AppPlansList :month="selectedMonth"></AppPlansList>
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-container {
  display: grid;
  grid-template-columns: 120px 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main {
  height: 100vh;

  padding: 0 30px 15%;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    height: auto;
    overflow: visible;
    grid-template-areas: 'header header' 'left left';
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: 3fr 3fr;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.app-aside-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .main {
    margin-bottom: 50px;
  }
  .app-aside {
    display: none;
  }
  .app-aside-mobile {
    display: block;
  }
  .app-container {
    margin-bottom: 30px;
  }
}

.main-header {
  display: flex;
  column-gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 20px;
}

.main-form {
  display: block;
}

.form-input:not(:last-child) {
  margin-bottom: 20px;
}
</style>
