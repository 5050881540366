<script setup>

import AppButtonSwitcher from '@/components/AppButtonSwitcher.vue'

const optionsForSwitcher = [
  {
    name: 'week',
    value: 'Неделя',
    isChecked: true
  },
  {
    name: 'month',
    value: 'Месяц',
    isChecked: false
  },
  {
    name: 'year',
    value: 'Год',
    isChecked: false
  }
]
</script>

<template>
<section class="home-rating-block">
  <AppButtonSwitcher :options="optionsForSwitcher"></AppButtonSwitcher>
</section>
</template>

<style scoped lang="scss">
.home-rating-block {
  margin-top: 20px;
}
</style>
