<script setup>
import { reactive } from 'vue'

function getCurrentTime () {
  const date = new Date()
  return {
    hours: date.getHours().toString().padStart(2, '0'),
    minutes: date.getMinutes().toString().padStart(2, '0')
  }
}

const time = reactive(getCurrentTime())
setInterval(() => {
  const currentTime = getCurrentTime()

  time.hours = currentTime.hours
  time.minutes = currentTime.minutes
}, 1000)
</script>

<template>
<div class="time-block">
  <p class="time hours">{{ time.hours }}</p>
  <p class="time separator">:</p>
  <p class="time minutes">{{ time.minutes }}</p>
</div>
</template>

<style scoped lang="scss">
.time-block {
  display: flex;
  gap: 1px;
}

.time {
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
</style>
