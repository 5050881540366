<script setup>
import AsideButtonMobile from '@/components/aside/AsideButtonMobile.vue'

const sections = [
  {
    name: '',
    icon: '/img/icons/home.svg',
    link: '/home'
  },
  {
    name: 'Задачи',
    icon: '/img/icons/task.svg',
    link: '/tasks'
  },
  {
    name: 'Планы',
    icon: '/img/icons/placemark.svg',
    link: '/plans'
  },
  {
    name: 'Мысли',
    icon: '/img/icons/brain.svg',
    link: '/thinks'
  },
  {
    name: 'Заметки',
    icon: '/img/icons/notepad.svg',
    link: '/notes'
  },
  {
    name: 'Меню',
    icon: '/img/icons/menu.svg',
    link: '/settings'
  }
]
</script>

<template>
  <div class="body">
    <ul class="btns-list" id="buttonsList">
      <li class="btns-list__item" v-for="btn in sections" :key="btn.name">
        <AsideButtonMobile :icon="btn.icon" :to="btn.link"></AsideButtonMobile>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;
@use '@/assets/scss/mixins' as *;

.body {
  position: fixed;
  bottom: 0;
  z-index: 999;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  @include removeScrollbar;

  padding: 15px;

  background-color: $accentColor;
}

.btns-list {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  column-gap: 20px;

  width: 100%;

  &__item {
    width: 100%;
  }
}
</style>
