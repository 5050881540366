<script setup>
import AppTasksList from '@/components/AppTasksList.vue'
import store from '@/store'
import { reactive, watch, toRaw } from 'vue'

const api = store.state.api

const props = defineProps({
  day: {
    type: Date,
    required: false,
    default: new Date()
  },
  showChart: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['tasksGot'])

const tasks = reactive({ tasks: await api.getTasksByDate(props.day) })
emit('tasksGot', tasks)

const fulldays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']
const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

function formatDate (dt) {
  const date = dt.getDate()
  const month = months[dt.getMonth()]
  const diffDays = new Date().getDate() - date
  const diffMonths = new Date().getMonth() - dt.getMonth()
  const diffYears = new Date().getFullYear() - dt.getFullYear()

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return 'Сегодня, ' + `${date} ${month}`
  } else if (diffYears === 0 && diffDays === 1) {
    return 'Вчера, ' + `${date} ${month}`
  } else if (diffYears === 0 && diffDays === -1) {
    return 'Завтра, ' + `${date} ${month}`
  } else if (diffYears === 0 && (diffDays < -1 && diffDays > -7)) {
    return fulldays[dt.getDay()]
  } else if (diffYears >= 1) {
    return `${date} ${month} ${dt.getFullYear()}`
  } else {
    return `${date} ${month}`
  }
}

watch(async () => {
  tasks.tasks = await api.getTasksByDate(props.day)
  emit('tasksGot', toRaw(tasks.tasks))
})
</script>

<template>
  <div class="tasks-list-block-block">
    <h2 class="date">{{ formatDate(props.day) }}</h2>
    <AppTasksList :tasks="tasks.tasks"></AppTasksList>
  </div>
</template>

<style scoped lang='scss'>
.date {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;

  margin-bottom: 10px;
}
</style>
