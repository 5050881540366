<script setup>
const props = defineProps({
  tag: {
    type: [String, Object],
    required: true
  }
})
</script>

<template>
<div class="tag-block" :style="{backgroundColor: tag.color ? tag.color : '#0094FF'}">{{ props.tag.name }}</div>
</template>

<style scoped lang="scss">
.tag-block {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2px 10px;
  margin-bottom: 3px;

  border-radius: 15px;

  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 15.6px */
}
</style>
