<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  background: {
    type: String,
    required: true
  }
})
</script>

<template>
<div class="link-button-block" :style="{background: props.background}">
  <div class="icon" :style="{backgroundImage: `url('${props.icon}')`}"></div>
  <p class="name">{{ props.name }}</p>
</div>
</template>

<style scoped lang="scss">
  @use '@/assets/scss/variables' as *;

  .link-button-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    max-width: 120px;

    padding: 30px 25px;

    border-radius: 15px;

    cursor: pointer;
    transition: $transition;

    &:hover {
      transform: scale(102%);
    }

    &:active {
      transform: scale(105%);
    }
  }

  .icon {
    width: 60px;
    height: 60px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .name {
    color: #FFFFFF;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
</style>
