<script setup>
import store from '@/store'
import router from '@/router'

const props = defineProps({
  avatar: String,
  name: String
})

async function logout () {
  if (confirm('Вы уверены, что хотите выйти?')) {
    await store.state.api.logout()
    router.push('/login')
  }
}
</script>

<template>
<div class="account" @click="logout">
  <div class="avatar" :style="{backgroundImage: `url('${props.avatar}')`}"></div>
  <p class="name">{{ name }}</p>
</div>
</template>

<style scoped lang="scss">
.account {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  width: 40px;
  height: 40px;

  border-radius: 50%;
  background-color: #0094FF; // TODO: Temporary! Must be deleted!

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.name {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 700px) {
    display: none;
  }
}
</style>
