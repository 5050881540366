<script setup>
import AppTaskTag from '@/components/AppTaskTag.vue'
import store from '@/store'

const props = defineProps({
  task: {
    type: Object,
    required: true
  }
})

const tags = props.task.tags ? props.task.tags : []

async function cancelTask () {
  await store.state.api.cancelTask(props.task.id)
}

async function rescheduleTask () {
  await store.state.api.rescheduleTask(props.task.id, new Date(new Date().setDate(new Date().getDate() + 1)))
}
</script>

<template>
  <div class="task-block" :style="{height: props.task.tags.length > 1 ? '60px' : '50px'}">
    <p class="task-name">{{ props.task.name }}</p>
    <div class="task-tags">
      <AppTaskTag class="task-tag" :tag="tag" v-for="tag in tags" :key="tag.name">{{ tag.name }}</AppTaskTag>
    </div>
    <div class="task-control">
      <button class="task-control-btn task-cancel" @click="cancelTask"></button>
      <button class="task-control-btn task-reschedule" @click="rescheduleTask"></button>
    </div>
    <input class="checkbox" type="checkbox" :checked="props.task.isCompleted" @change="store.state.api.setTaskCompleteStatus(props.task.id, $event.target.checked)">
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables.scss' as *;

.task-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 50px;

  padding-right: 15px;

  border-bottom: 1px solid #D9D9D9;
}

.task-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  padding-right: 10px;
}

.task-control {
  display: flex;
  align-items: center;

  column-gap: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.task-control-btn {
  transition: $transition;

  &:hover {
    transform: scale(105%);
  }

  &:active {
    transform: rotate(7deg) scale(110%);
  }
}

.task-reschedule {
  width: 20px;
  height: 25px;

  cursor: pointer;

  border: none;
  background: url("@/assets/img/icons/arrow-for-button.svg") no-repeat center;
  background-size: contain;

  transform: rotate(180deg);

  &:hover {
    transform: rotate(185deg) scale(105%);
  }

  &:active {
    transform: rotate(187deg) scale(110%);
  }
}

.task-cancel{
  width: 20px;
  height: 20px;

  cursor: pointer;

  border: none;
  background: url("@/assets/img/icons/cancel.svg") no-repeat center;
  background-size: contain;
}

.checkbox {
  width: 20px;
  height: 20px;

  outline: none;
  border-radius: 50%;
}
</style>
