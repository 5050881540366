<script setup>
import AppEvent from '@/components/AppEvent.vue'
import store from '@/store'

store.dispatch('updateEvents')
/* [
  {
    id: 0,
    date: new Date(),
    name: 'Встреча с руководителем',
    tags: [
      {
        name: 'Проект',
        color: '#008000'
      }
    ]
  },
  {
    id: 0,
    date: new Date(),
    name: 'Встреча с руководителем',
    tags: [
      {
        name: 'Проект',
        color: '#008000'
      }
    ]
  },
  {
    id: 0,
    date: new Date(),
    name: 'Встреча с руководителем',
    tags: [
      {
        name: 'Проект',
        color: '#008000'
      }
    ]
  },
  {
    id: 0,
    date: new Date(),
    name: 'Встреча с руководителем',
    tags: [
      {
        name: 'Проект',
        color: '#008000'
      }
    ]
  }
] ToDo: Temporary data! */
</script>

<template>
<ul class="app-events-list">
  <li class="app-events-list__item" v-for="event in store.state.eventsList" :key="event.id">
    <AppEvent :event="event"></AppEvent>
  </li>
</ul>
</template>

<style scoped lang="scss">
.app-events-list {
  display: flex;
  column-gap: 40px;
  flex-wrap: wrap;
}
</style>
