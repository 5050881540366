<script setup>

import AppNote from '@/components/AppNote.vue'

import store from '@/store'

const props = defineProps(['type'])

const api = store.state.api

let notes = []
if (props.type === 'this-week') {
  notes = await api.getThisWeekNotes()
} else {
  notes = await api.getLastNotes(1000, true)
}
</script>

<template>
<ul class="notes-notes-list">
  <li class="notes-notes-list__item" v-for="note in notes" :key="note.id">
    <AppNote :note="note" show-create-time show-deadline></AppNote>
  </li>
</ul>
</template>

<style scoped lang="scss">
.notes-notes-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 15px;
}
</style>
