<script setup>
import AppAside from '@/components/AppAside.vue'
import AppHeader from '@/components/AppHeader.vue'
import HomeGreeting from '@/components/home/HomeGreeting.vue'
import HomeWeather from '@/components/home/HomeWeather.vue'
import HomeQuote from '@/components/home/HomeQuote.vue'
import HomeTasks from '@/components/home/HomeTasks.vue'
import HomeNotes from '@/components/home/HomeNotes.vue'
import HomeNotifications from '@/components/home/HomeNotifications.vue'
import HomeIdeas from '@/components/home/HomeIdeas.vue'
import HomeRating from '@/components/home/HomeRating.vue'
import HomeButtons from '@/components/home/HomeButtons.vue'
import AppAsideMobile from '@/components/AppAsideMobile.vue'

document.querySelector('body').classList.remove('noFullScreen')
</script>

<template>
  <nav>
    <AppAsideMobile class="app-aside-mobile"></AppAsideMobile>
  </nav>
  <div class="app-container">
    <nav>
      <AppAside class="app-aside"></AppAside>
    </nav>
    <div class="main-block">
      <AppHeader></AppHeader>
      <main class="main">
        <div class="main-header">
          <HomeGreeting></HomeGreeting>
          <HomeWeather></HomeWeather>
          <HomeQuote class="home-quote"></HomeQuote>
        </div>
        <div class="main-bottom">
          <section class="left-section">
            <HomeTasks class="home-tasks"></HomeTasks>
            <HomeNotes></HomeNotes>
          </section>
          <section class="central-section">
            <HomeNotifications></HomeNotifications>
            <HomeIdeas></HomeIdeas>
          </section>
          <section class="right-section">
            <HomeRating></HomeRating>
            <HomeButtons></HomeButtons>
          </section>
        </div>
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/mixins' as *;

.app-container {
  display: grid;
  grid-template-columns: 120px 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main {
  height: 100vh;

  padding-bottom: 15%;
  padding-left: 30px;
  padding-right: 40px;

  overflow-y: scroll;

  @media screen and (max-width: 768px) {
    height: auto;
    overflow: visible;
  }
}

.app-aside-mobile {
  display: none;
}

.central-section {
  padding-top: 20px;
}

.main-header {
  display: flex;
  align-items: center;
  gap: 30px;
}

.home-quote {
  margin-left: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.main-bottom {
  display: grid;
  grid-template-columns: 4fr 3fr 3fr;
  gap: 50px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 3fr 3fr;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.home-tasks {
  margin-top: 15px;
}

// Media requests
@media screen and (max-width: 768px) {
  .main {
    margin-bottom: 50px;
  }
  .app-aside {
    display: none;
  }
  .app-aside-mobile {
    display: block;
  }
  .app-container {
    margin-bottom: 30px;
  }
}
// / Media requests
</style>
