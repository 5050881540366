<script setup>
import AppAddButton from '@/components/AppAddButton.vue'
import AppPlan from '@/components/AppPlan.vue'

import { computed } from 'vue'
import store from '@/store'

const props = defineProps({
  month: {
    type: Date,
    required: true
  }
})

function stringifyMonth (month) {
  const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

  return months[month]
}

function getDaysTillTheEndOfMonth () {
  const currentDate = new Date()
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
  return lastDayOfMonth.getDate() - currentDate.getDate()
}

function addPurpose () {
  const purpose = prompt('Название цели', 'Новая цель')
  store.dispatch('addPurpose', {
    year: props.month.getFullYear(),
    month: props.month.getMonth() + 1,
    name: purpose
  })
}

await store.dispatch('updateMonthPlans', {
  year: props.month.getFullYear(),
  month: props.month.getMonth() + 1
})

const currentDate = new Date()
const isCurrentMonth = computed(() => props.month.getMonth() === currentDate.getMonth() && props.month.getUTCFullYear() === currentDate.getFullYear())
</script>

<template>
  <div class="app-plans-list">
    <div class="heading">
      <h2 class="date-heading">{{ `${stringifyMonth(props.month.getMonth())} ${props.month.getFullYear()}` }}</h2>
      <p class="day-till-the-end-month" v-if="isCurrentMonth">({{ getDaysTillTheEndOfMonth() }} дней до конца)</p>
      <AppAddButton title="Создать цель" @click="addPurpose"></AppAddButton>
    </div>
    <ul class="plans-list">
      <li class="plans-list__item" v-for="plan in store.state.plansList.month[`${props.month.getFullYear()}-${props.month.getMonth() + 1}`]" :key="plan.id">
        <AppPlan :plan="plan" :month="month"></AppPlan>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.date-heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.day-till-the-end-month {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
