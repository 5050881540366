<script setup>
import { ref } from 'vue'
import store from '@/store'

const user = store.state.user.getName()

function updateCurrentHours () {
  const date = new Date()
  return date.getHours()
}

const currentHours = ref(updateCurrentHours())

setInterval(() => {
  currentHours.value = updateCurrentHours()
}, 1000)
</script>

<template>
  <h1 class="home-greeting" v-if="currentHours >= 0 && currentHours <= 3">Доброй ночи, {{ user }}!</h1>
  <h1 class="home-greeting" v-if="currentHours >= 4 && currentHours <= 11">Доброе утро, {{ user }}!</h1>
  <h1 class="home-greeting" v-if="currentHours >= 12 && currentHours <= 15">Добрый день, {{ user }}!</h1>
  <h1 class="home-greeting" v-if="currentHours >= 16 && currentHours <= 23">Добрый вечер, {{ user }}!</h1>
</template>

<style scoped lang="scss">
.home-greeting {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
