<script setup>
const quote = {
  text: 'В любой момент у нас есть два варианта: сделать шаг вперёд к росту или вернуться в безопасное место',
  author: 'Абрахам Маслоу - психолог'
}
</script>

<template>
<div class="home-quote">
  <blockquote class="quote quote-text">{{ quote.text }}</blockquote>
  <address class="quote quote-author">– {{ quote.author }}</address>
</div>
</template>

<style scoped lang="scss">
.quote {
  text-align: right;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
}

.quote-text {
  font-size: 18px;
  margin-bottom: 5px;
}

.quote-author {
  font-size: 15px;
}
</style>
