<script setup>

import AppAddButton from '@/components/AppAddButton.vue'
import AppIdea from '@/components/AppIdea.vue'
import store from '@/store'

store.dispatch('updateLastThinksList')
</script>

<template>
  <section class="home-ideas-block">
    <div class="heading-block">
      <h2 class="heading">Идеи, мысли</h2>
      <router-link to="/add-think"><AppAddButton></AppAddButton></router-link>
    </div>
    <ul class="ideas-list" v-if="store.state.lastThinksList">
      <li class="ideas-list__item" v-for="idea in store.state.lastThinksList" :key="idea.id">
        <AppIdea :idea="idea"></AppIdea>
      </li>
    </ul>
  </section>
</template>

<style scoped lang="scss">
.home-ideas-block {
  margin-top: 30px;
}

.heading-block {
  display: flex;
  align-items: center;
  gap: 10px;
}

.heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>
