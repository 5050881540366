<script setup>

import AppAddButton from '@/components/AppAddButton.vue'
import AppNote from '@/components/AppNote.vue'

import store from '@/store'

await store.dispatch('updateLastNotesList')

const notes = store.state.lastNotesList.slice(0, 5)
</script>

<template>
  <section class="home-notes-block">
    <div class="block-header">
      <h2 class="heading">Заметки</h2>
      <router-link to="/add-note">
        <AppAddButton></AppAddButton>
      </router-link>
    </div>
    <ul class="notes-list">
      <li class="notes-list__item" v-for="note in notes" :key="note.id">
        <AppNote :note="note" show-create-time show-deadline></AppNote>
      </li>
    </ul>
  </section>
</template>

<style scoped lang="scss">
.block-header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;

  margin-top: 15px;
  margin-bottom: 15px;
}

.heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.notes-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}
</style>
