<script setup>
import { computed } from 'vue'

import HeaderClock from '@/components/header/HeaderClock.vue'
import HeaderAccount from '@/components/header/HeaderAccount.vue'
import store from '@/store'

function hexToRgb (hex) {
  hex = hex.toUpperCase()
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

function getTimeColor () {
  const currentTime = new Date()
  switch (currentTime.getHours()) {
    case 0:
      return '#021327'
    case 1:
      return '#080522'
    case 2:
      return '#0D0322'
    case 3:
      return '#120826'
    case 4:
      return '#160F2B'
    case 5:
      return '#0B2D43'
    case 6:
      return '#004A5B'
    case 7:
      return '#007994'
    case 8:
      return '#159AB7'
    case 9:
      return '#4AB9D1'
    case 10:
      return '#79D4E8'
    case 11:
      return '#A7EFFF'
    case 12:
      return '#B1F1FF'
    case 13:
      return '#BBF3FE'
    case 14:
      return '#C5F6FE'
    case 15:
      return '#CDF7FE'
    case 16:
      return '#D7FAFE'
    case 17:
      return '#E1FCFE'
    case 18:
      return '#F3F1E3'
    case 19:
      return '#E7B78B'
    case 20:
      return '#874004'
    case 21:
      return '#11022C'
    case 22:
      return '#021327'
    case 23:
      return '#021327'
  }
}

const gradientColor = computed(() => {
  return hexToRgb(getTimeColor())
})
</script>

<template>
<div class="header" :style="{background: `linear-gradient(180deg, rgba(${gradientColor.r}, ${gradientColor.g}, ${gradientColor.b}, 0.42) 0%, rgba(255, 255, 255, 0.00) 100%)`}">
    <HeaderClock class="header-clock"></HeaderClock>
    <HeaderAccount class="header-account" avatar="/img/empty-avatar.svg" :name="store.state.user.getFullName()"></HeaderAccount>
</div>
</template>

<style scoped lang="scss">
.header {
  display: grid;
  grid-template-areas: 'empty clock account';

  justify-content: space-between;

  width: 100%;
  height: 90px;
}

.header-clock {
  grid-area: clock;
}

.header-account {
  grid-area: account;
  align-self: center;
  justify-self: end;

  margin-right: 40px;
}
</style>
