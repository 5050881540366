<script setup>
const props = defineProps({
  options: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['selectChanged'])
</script>

<template>
  <div class="button-switcher-block">
    <div class="button" v-for="option in props.options" :key="option.name">
      <input type="radio" class="btn-check" name="options-outlined" :id="option.name" autocomplete="off" :checked="option.isChecked" @change="emit('selectChanged', option.name)">
      <label class="btn btn-outline-success" :for="option.name">{{ option.value }}</label>
    </div>
  </div>
</template>

<style scoped lang="scss">
.button-switcher-block {
  @import '@/assets/scss/bootstrap/bootstrap.min.scss';

  display: flex;
  align-items: center;
  gap: 10px;
}

.button-switcher-block .btn-check:active + .btn-outline-success, .button-switcher-block .btn-check:checked + .btn-outline-success, .button-switcher-block .btn-outline-success.active, .button-switcher-block .btn-outline-success.dropdown-toggle.show, .button-switcher-block .btn-outline-success:active {
  border-radius: 15px;
  border: 2px solid #0094ff;
  outline: none;
  background: #0094ff;
  color: #fff;
}
</style>
