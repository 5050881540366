<script setup>

import AppAside from '@/components/AppAside.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppAddButton from '@/components/AppAddButton.vue'
import AppButtonSwitcher from '@/components/AppButtonSwitcher.vue'
import TasksListBlock from '@/components/tasks/TasksListBlock.vue'
import { nextTick, reactive, ref } from 'vue'
import AppHeading1 from '@/components/AppHeading1.vue'
import AppCalendarButton from '@/components/AppCalendarButton.vue'
import AppAsideMobile from '@/components/AppAsideMobile.vue'

const buttonSwitcherValues = [
  {
    name: 'yesterday',
    value: 'Вчера'
  },
  {
    name: 'tomorrow',
    value: 'Завтра',
    isChecked: true
  }
]

const selectedDay = reactive({})
selectedDay.value = new Date(new Date().setDate(new Date().getDate() + 1))

function changeDay (event) {
  if (event === 'yesterday') {
    selectedDay.value = new Date(new Date().setDate(new Date().getDate() - 1))
  } else if (event === 'tomorrow') {
    selectedDay.value = new Date(new Date().setDate(new Date().getDate() + 1))
  }
  forceRender('selectedDay')
}

const renderComponent = {
  today: ref(true),
  selectedDay: ref(true)
}

async function forceRender (item) {
  // Here, we'll remove MyComponent
  renderComponent[item].value = false
  // Then, wait for the change to get flushed to the DOM
  await nextTick()
  // Add MyComponent back in

  renderComponent[item].value = true
}

function tasksUpdate (event) {
  tasks = event
  countCompletedTasks()
  forceRender('today')
  forceRender('selectedDay')
}

function updateSelectedDay (event) {
  selectedDay.value = new Date(event)
  forceRender('selectedDay')
}

let tasks = []

const completedTasks = ref(0)
const countCompletedTasks = () => {
  let count = 0
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].isCompleted) count++
  }
  completedTasks.value = count
  return count
}
countCompletedTasks()
</script>

<template>
  <nav>
    <AppAsideMobile class="app-aside-mobile"></AppAsideMobile>
  </nav>
  <div class="app-container">
    <nav>
      <AppAside class="app-aside"></AppAside>
    </nav>
    <div class="main-block">
      <AppHeader></AppHeader>
      <main class="main">
        <div class="main-header">
          <AppHeading1>Задачи</AppHeading1>
          <router-link to="/add-task">
            <AppAddButton></AppAddButton>
          </router-link>
          <AppButtonSwitcher :options="buttonSwitcherValues" @selectChanged="changeDay($event)"></AppButtonSwitcher>
          <AppCalendarButton @change="updateSelectedDay"></AppCalendarButton>
        </div>
        <div class="left-tasks">
          <TasksListBlock v-if="renderComponent.today" key="0" :day="new Date()" @tasksGot="tasksUpdate($event)"></TasksListBlock>
          <p class="counter-completed-tasks" v-if="renderComponent.today">Выполнено <span>{{ completedTasks }}</span>
            задач из <span>{{
                tasks.length
              }}</span></p>
        </div>
        <div class="right-tasks">
          <TasksListBlock v-if="renderComponent.selectedDay" key="1" :day="selectedDay.value" @tasksGot="forceRender('selectedDay')"></TasksListBlock>
        </div>
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/mixins' as *;

.app-container {
  display: grid;
  grid-template-columns: 120px 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main {
  height: 100vh;

  padding: 0 30px 15%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'header header' 'left right';
  column-gap: 100px;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    height: auto;
    overflow: visible;
    grid-template-areas: 'header header' 'left left' 'right right';
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: 3fr 3fr;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.app-aside-mobile {
  display: none;
}

.left-tasks {
  grid-area: left;
}

.right-tasks {
  grid-area: right;
  //@media screen and (max-width: 768px) {
  //  display: none;
  //}
}

.main-header {
  display: flex;
  align-items: center;
  gap: 20px;

  grid-area: header;

  margin-bottom: 15px;
}

.counter-completed-tasks {
  font-size: 14px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;

  margin-top: 10px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    margin-bottom: 50px;
  }
  .app-aside {
    display: none;
  }
  .app-aside-mobile {
    display: block;
  }
  .app-container {
    margin-bottom: 30px;
  }
}
</style>
