<script setup>
const props = defineProps({
  direction: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="btn-block">
    <div class="app-slider-arrow-button" :class="{rotated: props.direction === 'right'}"></div>
  </div>
</template>

<style scoped lang="scss">
@use "@/assets/scss/variables" as *;

.btn-block {
  position: relative;
}

.app-slider-arrow-button {
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 50px;

    background-image: url("@/assets/img/icons/arrow-for-button.svg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.rotated {
  &::before {
    transform: rotate(180deg);
  }
}
</style>
