<script setup>
import store from '@/store'
import { ref } from 'vue'

const api = store.state.api
const token = ref('')

token.value = await api.getTelegramBotToken()

async function copyToken () {
  navigator.clipboard.writeText(token.value)
    .then(() => alert('Код скопирован!'))
}
</script>

<template>
  <div class="settings-token-component">
    <h2 class="settings-token-heading">Код для <a href="https://t.me/trackix_bot">Telegram-бота (@trackix_bot)</a></h2>
    <div class="token-block">
      <p class="settings-token">{{ token }}</p>
      <button class="copy-token" @click="copyToken">Скопировать</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "../../assets/scss/variables" as *;

.settings-token-heading {
  font-weight: normal;
  font-size: 22px;

  margin-bottom: 10px;
}

.token-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.settings-token {
  padding: 15px 20px;

  font-size: 18px;

  border: 2px solid #BBBBBB;
  border-radius: 20px;
}

.copy-token {
  padding: 15px 20px;

  border-radius: 20px;

  border: none;
  outline: none;
  background-color: $accentColor;
  color: #fff;

  cursor: pointer;

  transition: $transition;

  &:hover {
    background-color: darken($accentColor, 5%);
  }

  &:active {
    background-color: darken($accentColor, 10%);
    transform: scale(105%);
    box-shadow: $accentColor 0 0 10px;
  }
}
</style>
