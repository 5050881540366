import { createStore } from 'vuex'
import User from '@/classes/User'

export default createStore({
  state: {
    api: null,
    user: null,
    todayTasksList: null,
    lastNotesList: null,
    lastThinksList: null,
    thinksList: {
      today: null,
      thisWeek: null
    },
    plansList: {
      month: {},
      weekly: {}
    },
    eventsList: null
  },
  getters: {},
  mutations: {
    setAPI (state, api) {
      state.api = api
    },
    updateTodayTasksList (state, tasksList) {
      state.todayTasksList = tasksList
    },
    updateLastNotesList (state, notesList) {
      state.lastNotesList = notesList
    },
    updateLastThinksList (state, thinksList) {
      state.lastThinksList = thinksList
    },
    updateLastTodayThinksList (state, thinksList) {
      state.thinksList.today = thinksList
    },
    updateLastThisWeekThinksList (state, thinksList) {
      state.thinksList.thisWeek = thinksList
    },
    updateEvents (state, eventsList) {
      state.eventsList = eventsList
    },
    updateMonthPlans (state, plansList) {
      if (plansList[0]) {
        state.plansList.month[`${plansList[0].year}-${plansList[0].month}`] = plansList
      }
    },
    addSubtaskToMonthPlans (state, subtask) {
      for (const plan of state.plansList.month) {
        if (plan.id === subtask.id) {
          plan.subtasks.push(subtask)
        }
      }
    },
    setUser (state, user) {
      if (!user) state.user = null
      else state.user = new User(user.id, user.name, user.surname, user.username, user.city, user.email)
    }
  },
  actions: {
    async updateTodayTasksList ({ commit, state }) {
      const tasksList = await state.api.getTodayTasks()
      commit('updateTodayTasksList', tasksList)
    },
    async updateLastNotesList ({ commit, state }) {
      const notesList = await state.api.getLastNotes(1000)
      commit('updateLastNotesList', notesList)
    },
    async updateLastThinksList ({ commit, state }) {
      const thinksList = await state.api.getLastThinks(5)

      for (let i = 0; i < thinksList.length; i++) {
        thinksList[i].tags = JSON.parse(thinksList[i].tags)
      }
      commit('updateLastThinksList', thinksList)
    },
    async updateLastTodayThinksList ({ commit, state }) {
      const thinksList = await state.api.getLastTodayThinks(1000)

      for (let i = 0; i < thinksList.length; i++) {
        thinksList[i].tags = JSON.parse(thinksList[i].tags)
      }
      commit('updateLastTodayThinksList', thinksList)
    },
    async updateLastThisWeekThinksList ({ commit, state }) {
      const thinksList = await state.api.getLastThisWeekThinks(1000)

      for (let i = 0; i < thinksList.length; i++) {
        thinksList[i].tags = JSON.parse(thinksList[i].tags)
      }
      commit('updateLastThisWeekThinksList', thinksList)
    },
    async updateMonthPlans ({ commit, state }, payload) {
      const plansList = await state.api.getMonthPlans(payload.year, payload.month)
      console.log(plansList)
      commit('updateMonthPlans', plansList)
    },
    async updateEvents ({ commit, state }, payload) {
      const eventsList = await state.api.getEvents()
      for (let i = 0; i < eventsList.length; i++) {
        eventsList[i].tags = JSON.parse(eventsList[i].tags)
        eventsList[i].date = new Date(eventsList[i].date)
      }
      commit('updateEvents', eventsList)
    },
    async addPurpose ({ state, dispatch }, payload) {
      await state.api.addPurpose(payload.year, payload.month, payload.name)
      dispatch('updateMonthPlans', { year: payload.year, month: payload.month })
    },
    async addSubtask ({ state, dispatch }, payload) {
      await state.api.addSubtask(payload.id, payload.name)
      dispatch('updateMonthPlans', { year: payload.year, month: payload.month })
    }
  },
  modules: {}
})
