<script setup>
import store from '@/store'
import router from '@/router'
import { ref } from 'vue'
import VueCookies from 'vue-cookie'

document.querySelector('body').classList.add('noFullScreen')

const name = ref('')
const surname = ref('')
const username = ref('')
const email = ref('')
const password = ref('')

async function formSubmit () {
  if (!name.value || !surname.value || !username.value || !email.value || !password.value) {
    alert('Заполните все поля!')
  }
  const result = await store.state.api.register(name.value, surname.value, username.value, email.value, password.value)
  if (!result) alert('Не удалось зарегистрироваться! Возникла ошибка!')
  else {
    await store.state.api.authenticate(email.value, password.value)
    VueCookies.set('user', JSON.stringify(store.state.user.getJSON()), '7d')
    await router.push('/email-verification')
  }
}
</script>

<template>
  <div class="register-view">
    <h1 class="register-heading">Регистрация</h1>
    <form class="register-form">
      <div class="left-part">
        <label for="password" class="register-label">Имя</label>
        <input class="register-form-text-input" id="registerName" type="text" placeholder="Имя" v-model="name">
        <label for="password" class="register-label">Фамилия</label>
        <input class="register-form-text-input" id="registerSurname" type="text" placeholder="Фамилия"
               v-model="surname">
        <label for="password" class="register-label">Имя пользователя</label>
        <input class="register-form-text-input" id="registerUsername" type="text" placeholder="Имя пользователя"
               v-model="username">
        <label for="password" class="register-label">Эл. почта</label>
        <input class="register-form-text-input" id="registerEmail" type="email" placeholder="Эл. почта" v-model="email">
        <label for="password" class="register-label">Пароль</label>
        <input class="register-form-text-input" id="registerPassword" type="password" placeholder="Пароль"
               v-model="password">
      </div>
      <button class="register-form-button" @click.prevent="formSubmit">Войти</button>
    </form>
  </div>
</template>

<style scoped lang="scss">
@use "/src/assets/scss/variables" as *;

.register-heading {
  color: #FFFFFF;
  font-size: 40px;
}

.register-label {
  align-self: flex-start;

  color: #555555;
  margin-bottom: 15px;
  margin-left: 5px;
  font-size: 18px;
}

.register-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 40px;

  gap: 50px;

  min-height: 100vh;

  background-color: $accentColor;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 60px;

  border-radius: 20px;
  background-color: #fff;

  box-shadow: #fff 0 0 20px;
}

.left-part {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form-text-input {
  padding: 15px 30px;

  outline: none;
  border: none;
  border-radius: 20px;

  box-shadow: #999 0 0 10px;

  transition: $transition;

  &:hover {
    box-shadow: #777 0 0 10px;
  }

  &:focus {
    box-shadow: $accentColor 0 0 15px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.register-form-button {
  margin-top: 20px;
  padding: 15px 30px;

  border-radius: 20px;

  border: none;
  outline: none;
  background-color: $accentColor;
  color: #fff;

  cursor: pointer;

  transition: $transition;

  &:hover {
    background-color: darken($accentColor, 5%);
  }

  &:active {
    background-color: darken($accentColor, 10%);
    transform: scale(105%);
    box-shadow: $accentColor 0 0 10px;
  }
}
</style>
