<script setup>

import AppAsideMobile from '@/components/AppAsideMobile.vue'
import AppHeading1 from '@/components/AppHeading1.vue'
import AppAside from '@/components/AppAside.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppTextInput from '@/components/AppTextInput.vue'
import AppFormSubmit from '@/components/AppFormSubmit.vue'
import AppDatetimeInput from '@/components/AppDatetimeInput.vue'

import { useRouter } from 'vue-router'

import store from '@/store'

const router = useRouter()
const api = store.state.api

async function formSubmitted () {
  const text = document.querySelector('#addNoteFormInputText').value
  let tags = document.querySelector('#addNoteFormInputTags').value
  const date = new Date(document.querySelector('#addNoteFormInputDate').value)

  tags = tags.split(', ')

  try {
    const response = await api.addNote(text, tags, date)
    if (response) {
      router.back()
    } else alert('Возникла ошибка при создании заметки')
  } catch (e) {
    alert('Возникла ошибка при создании заметки')
  }
}

const date = new Date()
</script>

<template>
  <nav>
    <AppAsideMobile class="app-aside-mobile"></AppAsideMobile>
  </nav>
  <div class="app-container">
    <nav>
      <AppAside class="app-aside"></AppAside>
    </nav>
    <div class="main-block">
      <AppHeader></AppHeader>
      <main class="main">
        <div class="main-header">
          <AppHeading1>Новая заметка</AppHeading1>
        </div>
        <form class="main-form">
          <AppTextInput class="form-input" id="addNoteFormInputText" name="Заметка"
                        placeholder="Заметка"/>
          <AppTextInput class="form-input" id="addNoteFormInputTags" name="Тэги"
                        placeholder="Через запятую (Работа, Учёба )"/>
          <AppDatetimeInput name="taskDate" id="addNoteFormInputDate" label="Дата" :default-date="`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.getHours()}:${date.getMinutes()}`" />
          <AppFormSubmit id="mainFormSubmit" value="Создать" @submit="formSubmitted"></AppFormSubmit>
        </form>
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-container {
  display: grid;
  grid-template-columns: 120px 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main {
  height: 100vh;

  padding: 0 30px 15%;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    height: auto;
    overflow: visible;
    grid-template-areas: 'header header' 'left left';
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: 3fr 3fr;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.app-aside-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .main {
    margin-bottom: 50px;
  }
  .app-aside {
    display: none;
  }
  .app-aside-mobile {
    display: block;
  }
  .app-container {
    margin-bottom: 30px;
  }
}

.main-header {
  margin-bottom: 20px;
}

.main-form {
  display: block;
}

.form-input:not(:last-child) {
  margin-bottom: 20px;
}
</style>
