<script setup>
const props = defineProps({
  value: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: false,
    default: null
  }
})

const emit = defineEmits(['click'])
</script>

<template>
<div class="app-notification-question-button-block" @click="emit('click')">
  <div class="icon" v-if="props.icon" :style="{backgroundImage: `url('${props.icon}')`}"></div>
  <p class="button-name">{{ props.value }}</p>
</div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.app-notification-question-button-block {
  padding: 5px 15px;

  border-radius: 5px;
  background: #FFF;

  cursor: pointer;

  transition: $transition;

  &:active {
    transform: scale(105%);
  }
}

.icon {
  width: 25px;
  height: 25px;

  margin-bottom: 5px;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.button-name {
  color: $accentColor;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
</style>
