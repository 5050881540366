<script setup>
import { ref, onMounted } from 'vue'

const emit = defineEmits(['change'])

const isDateSelectorView = ref(false)

let dateSelector
onMounted(() => {
  dateSelector = document.querySelector('#tasksDateSelector')
})

function calendarClick () {
  isDateSelectorView.value = !isDateSelectorView.value
  if (isDateSelectorView.value) {
    setTimeout(() => {
      dateSelector.showPicker()
    }, 20)
  }
}
</script>

<template>
  <div class="icon" @click="calendarClick"></div>
  <input v-show="isDateSelectorView" type="date" id="tasksDateSelector" @change="emit('change', dateSelector.value)">
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.icon {
  width: 25px;
  height: 25px;

  background-image: url("@/assets/img/icons/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  cursor: pointer;
  transition: $transition;

  &:hover {
    transform: scale(105%);
  }

  &:active {
    transform: scale(110%);
  }
}
</style>
