<template>
  <p class="in-dev">Данный функционал ещё находится в разработке, следите за обновлениями в <a href="https://vk.com/trackix">ВК: @trackix</a></p>
</template>

<style scoped>
.in-dev {
  margin-top: 15px;

  font-size: 18px;
  font-weight: 300;
}
</style>
