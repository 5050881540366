<script setup>
const props = defineProps(['id', 'name', 'label', 'defaultDate'])
</script>

<template>
  <label class="app-date-input__label" :for="props.id">{{ label }}</label>
  <input class="app-date-input" type="date" :name="props.name" :id="props.id" :value="props.defaultDate">
</template>

<style scoped>
.app-date-input {
  display: block;

  margin-bottom: 15px;
  padding: 10px 15px 10px 15px;

  border: none;
  outline: none;
  border-radius: 25px;
  box-shadow: 0px 2px 10px 0px rgba(0, 74, 173, 0.45);
}

.app-date-input__label {
  display: block;
  margin-bottom: 10px;
}
</style>
