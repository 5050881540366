<script setup>

import AppNotification from '@/components/AppNotification.vue'

const notifications = []

/* const notifications = [
  {
    id: 1,
    type: 'miss',
    text: 'Вчера вы не выполнили две задачи'
  },
  {
    id: 2,
    type: 'miss',
    text: 'Вы не занимались спортом в течение 2 дней'
  },
  {
    id: 3,
    type: 'question',
    text: 'Вопрос',
    answers: ['Первый', 'Второй', 'Третий']
  },
  {
    id: 4,
    type: 'info',
    text: 'Что нового?'
  }
]  ToDo: Temporary! */
</script>

<template>
  <section>
    <ul class="home-notifications-list">
      <li class="home-notifications-list__item" v-for="notification in notifications" :key="notification.id">
        <AppNotification :notification="notification"></AppNotification>
      </li>
    </ul>
  </section>
</template>

<style scoped lang="scss">
.home-notifications-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}
</style>
