<script setup>

import AppTaskTag from '@/components/AppTaskTag.vue'

const props = defineProps({
  event: {
    type: Object,
    required: true
  }
})

function getRussianWeekdayAbbreviation (date) {
  switch (date.getDay()) {
    case 1:
      return 'ПН'
    case 2:
      return 'ВТ'
    case 3:
      return 'СР'
    case 4:
      return 'ЧТ'
    case 5:
      return 'ПТ'
    case 6:
      return 'СБ'
    case 7:
      return 'ВС'
  }
}

function isWeekend (date) {
  return date.getDay() >= 6
}
</script>

<template>
<article class="app-event-block">
  <div class="date" :style="{color: isWeekend(props.event.date) ? '#D20000' : '#000000'}">
    <p class="week-day">{{ getRussianWeekdayAbbreviation(props.event.date) }}</p>
    <p class="number-day">{{ props.event.date.getDate() }}</p>
  </div>
  <p class="name">{{ props.event.name }}</p>
  <ul class="tags-list">
    <li class="tags-list__item" v-for="tag in props.event.tags" :key="tag.name">
      <AppTaskTag :tag="tag"></AppTaskTag>
    </li>
  </ul>
  <div class="buttons">
    <div class="delete-button"></div>
    <input class="checkbox" type="checkbox">
  </div>
</article>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.app-event-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 40vw;
  padding: 10px 5px;

  border-bottom: 1px solid #D9D9D9;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 20px;
}

.week-day {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.number-day {
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 96%;
}

.name {
  margin-right: 20px;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.tags-list {
  display: flex;
  flex-direction: column;
  gap: 5px;

  margin-right: 20px;
}

.buttons {
  display: flex;
  gap: 15px;
}

.delete-button {
  width: 20px;
  height: 20px;

  background-image: url("@/assets/img/icons/cancel.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  cursor: pointer;

  transition: $transition;

  &:hover {
    transform: scale(105%);
  }

  &:active {
    transform: scale(110%) rotate(5deg);
  }
}

.checkbox {
  width: 20px;
  height: 20px;
}
</style>
