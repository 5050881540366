<script setup>
const props = defineProps({
  weather: {
    type: String,
    required: true
  }
})

const date = new Date()
let dailyTimeFolder = 'daily'
if (!(date.getHours() >= 6 && date.getHours() <= 18)) dailyTimeFolder = 'nightly'

const icons = {
  cloud: '/img/icons/weather/cloud.svg',
  rainCloud: '/img/icons/weather/rainCloud.svg',
  rainThunderstormCloud: '/img/icons/weather/rainThunderstormCloud.svg',
  snow: '/img/icons/weather/snow.svg',
  snowCloud: '/img/icons/weather/snowCloud.svg',
  cloudSun: `/img/icons/weather/${dailyTimeFolder}/cloudSun.svg`,
  rainCloudSun: `/img/icons/weather/${dailyTimeFolder}/rainCloudSun.svg`,
  sun: `/img/icons/weather/${dailyTimeFolder}/sun.svg`
}
</script>

<template>
<div class="weather-icon" :style="{backgroundImage: `url('${icons[props.weather]}')`}"></div>
</template>

<style scoped lang="scss">
.weather-icon {
  width: 50px;
  height: 50px;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>
