<script setup>

const props = defineProps({
  icon: String,
  to: String
})
</script>

<template>
  <router-link :to="props.to">
    <div class="button">
      <div class="icon" :style="{backgroundImage: `url('${props.icon}')`}"></div>

      <p class="name">
        <slot class="name"></slot>
      </p>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
@use '@/assets/scss/variables' as *;

.button {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  transition: $transition;

  &:hover {
    transform: translateX(5%);
  }
}

.icon {
  width: 40px;
  height: 40px;

  margin-bottom: 5px;

  background-repeat: no-repeat;
  background-size: cover;
}

.name {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
