import store from '@/store'

export default class User {
  constructor (id, name, surname, username, city, email) {
    this.id = id
    this.name = name
    this.surname = surname
    this.username = username
    this.city = city
    this.email = email
    this.api = store.state.api
  }

  async getTodayTasks () {
    return await this.api.getTodayTasks(this.id)
  }

  async isEmailVerified () {
    return await this.api.checkEmailVerification()
  }

  getCity () {
    return this.city
  }

  getName () {
    return this.name
  }

  getSurname () {
    return this.surname
  }

  getFullName () {
    return this.name + ' ' + this.surname
  }

  getJSON () {
    return {
      id: this.id,
      name: this.name,
      surname: this.surname,
      username: this.username,
      city: this.city,
      email: this.email
    }
  }
}
