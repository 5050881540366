<script setup>
import store from '@/store'
import router from '@/router'
import VueCookies from 'vue-cookie'

if (store.state.user) router.push('/home')

document.querySelector('body').classList.add('noFullScreen')

async function formSubmit (email, password) {
  if (!email || !password) {
    email = document.querySelector('#loginFormEmail')
    password = document.querySelector('#loginFormPassword')
  }
  if (!email.value || !password.value) {
    alert('Введите эл. почту и пароль!')
  }

  const isSuccessAuthenticate = await store.state.api.authenticate(email.value, password.value)
  if (isSuccessAuthenticate) {
    VueCookies.set('user', JSON.stringify(store.state.user.getJSON()), '7d')
    await router.push('/home')
  } else {
    alert('Не удалось авторизоваться!')
  }
}

function trial () {
  formSubmit({ value: 'trial@trackix.ru' }, { value: '123456' })
}
</script>

<template>
  <div class="login-view">
    <h1 class="login-heading">Вход</h1>
    <form class="login-form">
      <label for="loginFormEmail" class="login-label">Эл. почта</label>
      <input class="login-form-text-input" id="loginFormEmail" type="email" placeholder="Эл. почта">
      <label for="loginFormPassword" class="login-label">Пароль</label>
      <input class="login-form-text-input" id="loginFormPassword" type="password" placeholder="Пароль">
      <button class="login-form-button" @click.prevent="formSubmit">Войти</button>
      <router-link class="login-register-link" to="/register">Зарегистрироваться</router-link>
      <p class="login-register-link" style="cursor: pointer" @click="trial()">Пробный доступ</p>
    </form>
  </div>
</template>

<style scoped lang="scss">
@use "/src/assets/scss/variables" as *;

.login-heading {
  color: #FFFFFF;
  font-size: 40px;
}

.login-label {
  align-self: flex-start;

  color: #555555;
  margin-bottom: 15px;
  margin-left: 5px;
  font-size: 18px;
}

.login-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 40px;

  gap: 50px;

  min-height: 100vh;

  background-color: $accentColor;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 60px;

  border-radius: 20px;
  background-color: #fff;

  box-shadow: #fff 0 0 20px;
}

.left-part {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-text-input {
  padding: 15px 30px;

  outline: none;
  border: none;
  border-radius: 20px;

  box-shadow: #999 0 0 10px;

  transition: $transition;

  &:hover {
    box-shadow: #777 0 0 10px;
  }

  &:focus {
    box-shadow: $accentColor 0 0 15px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.login-form-button {
  margin-top: 20px;
  padding: 15px 30px;

  border-radius: 20px;

  border: none;
  outline: none;
  background-color: $accentColor;
  color: #fff;

  cursor: pointer;

  transition: $transition;

  &:hover {
    background-color: darken($accentColor, 5%);
  }

  &:active {
    background-color: darken($accentColor, 10%);
    transform: scale(105%);
    box-shadow: $accentColor 0 0 10px;
  }
}

.login-register-link {
  margin-top: 20px;

  color: $accentColor;

  &:hover {
    color: darken($accentColor, 5%);
  }
}
</style>
