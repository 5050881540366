<script setup>
const emit = defineEmits(['change'])

function emitChange (event) {
  emit('change', event.target.value)
}
</script>

<template>
  <input @change="emitChange($event)" class="month-input" type="month" name="" id="monthPicker">
</template>

<style scoped lang="scss">
.month-input {
  height: 30px;
  padding: 15px;

  border: 2px solid #BBBBBB;
  outline: none;
  border-radius: 10px;
}
</style>
