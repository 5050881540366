<script setup>

import AppLinkButton from '@/components/AppLinkButton.vue'

const buttons = [
  {
    name: 'Сон',
    icon: '/img/icons/moon.svg',
    background: 'radial-gradient(138.1% 164.82% at 117.08% -16%, #000 0%, #000B6F 70.31%, #00655F 100%)'
  },
  {
    name: 'Настроение',
    icon: '/img/icons/smile.svg',
    background: 'radial-gradient(138.1% 164.82% at 117.08% -16%, #F90 0%, #FF3F3F 70.31%, #FFB800 100%)'
  },
  {
    name: 'Финансы',
    icon: '/img/icons/coin.svg',
    background: 'radial-gradient(138.1% 164.82% at 117.08% -16%, #C8B400 0%, #128400 70.31%, #0E6200 100%)'
  }
]

function notRealized () {
  alert('Этот функционал ещё не реализован, следите за обновлениями')
}
</script>

<template>
<nav class="home-buttons-block">
  <ul class="btns-list">
    <li class="btns-list__item" v-for="button in buttons" :key="button.name">
      <AppLinkButton :name="button.name" :icon="button.icon" :background="button.background" @click="notRealized"></AppLinkButton>
    </li>
  </ul>
</nav>
</template>

<style scoped lang="scss">
.home-buttons-block {
  margin-top: 30px;
}

.btns-list {
  display: flex;
  gap: 15px;
}
</style>
