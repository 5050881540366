<script setup>
import store from '@/store'
import VueCookie from 'vue-cookie'

import API from '@/classes/API'
import router from '@/router'
import { ref } from 'vue'

store.commit('setAPI', new API(process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : 'https://trackix.ru:8080/api', store))

const user = JSON.parse(VueCookie.get('user'))

const isAllowToRender = ref(false)

if (user && await store.state.api.checkSession()) store.commit('setUser', user)
else {
  store.state.user = null
  VueCookie.delete('user')
  if (window.location.pathname !== '/register') router.push('/login')
  isAllowToRender.value = true
}
</script>

<template>
  <router-view v-if="store.state.user || router.currentRoute.value.path === '/login' || router.currentRoute.value.path === '/register'" />
</template>

<style lang="scss">
</style>
